import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/user/Login';
import Home from './components/user/Home';
import Quiz from './components/user/Quiz';
import Study from './components/user/Study';
import History from './components/user/History';
import Exam from './components/user/Exam'; // Thêm component mới
import ExamQuiz from './components/user/ExamQuiz'; // Thêm component mới
import AdminDashboard from './components/admin/AdminDashboard';
import AdminLogin from './components/admin/AdminLogin';
import PrivateRoute from './components/common/PrivateRoute';
import ManageUsers from './components/admin/ManageUsers';
import ManageQuestions from './components/admin/ManageQuestions';
import ManageCategories from './components/admin/ManageCategories';
import ManageExamConfigs from './components/admin/ManageExamConfigs';
import Leaderboard from './components/user/Leaderboard';
import Author from './components/user/Author';

function App() {
    return (
        <Router>
            <Routes>
                {/* Trang đăng nhập cho user */}
                <Route path="/" element={<Login />} />

                {/* Trang đăng nhập Admin */}
                <Route path="/admin-login" element={<AdminLogin />} />

                {/* Các route cần bảo vệ dành cho user */}
                <Route element={<PrivateRoute />}>
                    <Route path="/home" element={<Home />} />
                    <Route path="/study" element={<Study />} />
                    <Route path="/quiz" element={<Quiz />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                     <Route path="/author" element={<Author />} />
                    {/* Thêm routes mới cho phần thi thử */}
                    <Route path="/exam" element={<Exam />} />
                    <Route path="/exam-quiz/:examId" element={<ExamQuiz />} />
                </Route>

                {/* Route riêng cho Admin */}
                <Route element={<PrivateRoute role="admin" />}>
                    <Route path="/admin" element={<AdminDashboard />} />
                    <Route path="/admin/manage-users" element={<ManageUsers />} />
                    <Route path="/admin/manage-questions" element={<ManageQuestions />} />                   
                    <Route path="/admin/manage-categories" element={<ManageCategories />} />
                    <Route path="/admin/manage-exam-configs" element={<ManageExamConfigs />} />
                    <Route path="/admin/leaderboard" element={<Leaderboard />} />
                </Route>

                {/* Chuyển hướng nếu đường dẫn không tồn tại */}
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;