// frontend/src/components/common/Footer.js
import React from 'react';
import '../../styles/layout.css';

function Footer() {
    return (
        <footer className="footer">
            <p>Agriquiz © 2024 by Phan Tiến</p>
        </footer>
    );
}

export default Footer;
