import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../common/Header';
import '../../styles/ExamHistory.css';

function ExamHistory() {
    const navigate = useNavigate();
    const [examHistory, setExamHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [analysis, setAnalysis] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    const formatDateTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        return date.toLocaleString('vi-VN', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    // Format thời gian theo giây
    const formatDuration = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const fetchExamHistory = async () => {
            const token = localStorage.getItem('authToken');
            if (!token) {
                setError('Vui lòng đăng nhập để xem lịch sử bài thi.');
                navigate('/login');
                return;
            }

            try {
                const response = await fetch(`${apiUrl}/exam_history.php`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                if (data.status === 'success') {
                    setExamHistory(data.history);
                    setAnalysis(data.analysis);
                } else {
                    setError(data.message || 'Lỗi khi lấy lịch sử bài thi.');
                }
            } catch (error) {
                setError('Lỗi kết nối server: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExamHistory();
    }, [navigate, apiUrl]);

    if (loading) {
        return (
            <div className="exam-history-page">
                <Header />
                <div className="exam-history-container">
                    <div className="loading-message">Đang tải lịch sử bài thi...</div>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="exam-history-page">
                <Header />
                <div className="exam-history-container">
                    <div className="error-message">{error}</div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="exam-history-container">
                <div className="exam-history-header">
                    <h2>Lịch sử bài thi</h2>
                    <Link to="/home" className="back-button">← Trở về</Link>
                </div>

                {analysis && (
                    <div className="exam-analysis-panel">
                        <div className="analysis-stats">
                            <div className="stat-item">
                                <span className="stat-value">{analysis.average_accuracy}%</span>
                                <span className="stat-label">Tỉ lệ đúng trung bình</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-value">{analysis.best_accuracy}%</span>
                                <span className="stat-label">Tỉ lệ đúng cao nhất</span>
                            </div>
                            <div className="stat-item">
                                <span className="stat-value">{analysis.average_speed}s</span>
                                <span className="stat-label">Thời gian/câu đúng</span>
                            </div>
                        </div>

                        <div className="quick-insights">
                            <p className="insight">📊 {analysis.accuracy_assessment}</p>
                            <p className="insight">⏱️ {analysis.speed_assessment}</p>
                            <p className="insight">💡 {analysis.study_tip}</p>
                        </div>
                    </div>
                )}

                {examHistory.length > 0 ? (
                    <table className="history-table">
                        <thead>
                            <tr>
                                <th>Bài thi</th>
                                <th>Thời gian</th>
                                <th>Thời lượng</th>
                                <th>Kết quả</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examHistory.map((exam) => (
                                <tr key={exam.exam_id}>
                                    <td>{exam.exam_name}</td>
                                    <td>
                                        Bắt đầu: {formatDateTime(exam.start_time)}<br/>
                                        Kết thúc: {formatDateTime(exam.end_time)}
                                    </td>
                                    <td>{formatDuration(exam.duration)}</td>
                                    <td>
                                        {exam.correct_answers}/{exam.total_questions}
                                        <span className="score-percent">
                                            ({((exam.correct_answers/exam.total_questions) * 100).toFixed(1)}%)
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className="no-data">Chưa có lịch sử bài thi</div>
                )}
            </div>
        </div>
    );
}

export default ExamHistory;