import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../styles/manage-categories.css'; // Giao diện
import { Link } from 'react-router-dom'; // Thêm import Link

function ManageCategories() {
    const [categories, setCategories] = useState([]); // Danh sách danh mục
    const [searchTerm, setSearchTerm] = useState(''); // Tìm kiếm
    const [showModal, setShowModal] = useState(false); // Hiển thị modal
    const [newCategory, setNewCategory] = useState(''); // Tên danh mục mới
    const [message, setMessage] = useState(''); // Thông báo

    useEffect(() => {
        fetchCategories();
    }, []);

    // Lấy URL từ biến môi trường
    const apiUrl = process.env.REACT_APP_API_URL;

    // Fetch danh sách danh mục từ server
    const fetchCategories = async () => {
        try {
            const response = await fetch(`${apiUrl}/categories.php`);
            const data = await response.json();
            if (data.status === 'success') {
                setCategories(data.categories);
            } else {
                setMessage('Không thể tải danh mục');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    // Thêm danh mục mới
    const handleAddCategory = async (e) => {
        e.preventDefault();
        if (!newCategory) return;

        try {
            const response = await fetch(`${apiUrl}/categories.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name: newCategory }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                fetchCategories();
                setShowModal(false);
                setNewCategory('');
                setMessage('Thêm danh mục thành công');
            } else {
                setMessage(data.message || 'Lỗi khi thêm danh mục');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    // Sửa danh mục
    const handleEditCategory = async (category) => {
        const newName = prompt('Nhập tên danh mục mới:', category.name);
        if (newName) {
            try {
                const response = await fetch(`${apiUrl}/categories.php`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id: category.id, name: newName }),
                });
                const data = await response.json();
                if (data.status === 'success') {
                    fetchCategories();
                    setMessage('Cập nhật danh mục thành công');
                } else {
                    setMessage(data.message || 'Lỗi khi cập nhật danh mục');
                }
            } catch (error) {
                setMessage('Lỗi kết nối server');
            }
        }
    };

    // Xóa danh mục
    const handleDeleteCategory = async (id) => {
        if (window.confirm('Bạn có chắc chắn muốn xóa danh mục này?')) {
            try {
                const response = await fetch(`${apiUrl}/categories.php`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id }),
                });
                const data = await response.json();
                if (data.status === 'success') {
                    fetchCategories();
                    setMessage('Xóa danh mục thành công');
                } else {
                    setMessage(data.message || 'Lỗi khi xóa danh mục');
                }
            } catch (error) {
                setMessage('Lỗi kết nối server');
            }
        }
    };

    const filteredCategories = categories.filter((category) =>
        category.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="manage-categories-page">
            <Header />
            <main className="dashboard-container">
                <h2 className="dashboard-title">Quản lý danh mục</h2>
                
                {/* Thêm menu "Trở về Dashboard" */}
                <Link to="/admin" className="back-to-dashboard">
                    ← Trở về Dashboard
                </Link>
                
                {/* Phần còn lại của giao diện */}
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Tìm kiếm danh mục..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button onClick={() => setShowModal(true)} className="btn-add-new">+</button>
                </div>

                {message && <p className="message">{message}</p>}

                <table className="categories-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Tên danh mục</th>
                            <th>Chức năng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCategories.map((category) => (
                            <tr key={category.id}>
                                <td>{category.id}</td>
                                <td>{category.name}</td>
                                <td className="table-actions">
                                    <button className="btn-edit" onClick={() => handleEditCategory(category)}>Sửa</button>
                                    <button className="btn-delete" onClick={() => handleDeleteCategory(category.id)}>Xóa</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </main>
            <Footer />

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Thêm danh mục mới</h3>
                        <form onSubmit={handleAddCategory}>
                            <input
                                type="text"
                                placeholder="Tên danh mục"
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                required
                            />
                            <div className="modal-actions">
                                <button type="submit" className="btn-primary">Thêm</button>
                                <button type="button" className="btn-secondary" onClick={() => setShowModal(false)}>Hủy</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ManageCategories;
