import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';

import '../../styles/exam.css';
import { Link } from 'react-router-dom'; // Thêm Link để chuyển trang

function ExamSelection() {
    const [examTypes, setExamTypes] = useState([]);
    const [selectedExam, setSelectedExam] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL; // Lấy URL từ biến môi trường

    useEffect(() => {
        fetchExamTypes();
    }, []);

    const fetchExamTypes = async () => {
        try {
            const response = await fetch(`${apiUrl}/exam_types.php`);
            const data = await response.json();
            if (data.status === 'success') {
                setExamTypes(data.examTypes);
            } else {
                setError('Không thể tải danh sách đề thi.');
            }
        } catch (error) {
            setError('Lỗi kết nối server.');
        }
    };

    const handleStartExam = () => {
        if (!selectedExam) {
            setError('Vui lòng chọn đề thi');
            return;
        }
        
        navigate(`/exam-quiz/${selectedExam}`);
    };

    const selectedExamData = examTypes.find(exam => exam.id === parseInt(selectedExam));

    return (
        <div className="exam-page">
            <Header />
            <main className="exam-container">
                
 <Link to="/home" className="back-to-dashboard">
                    ← Trở về 
                </Link>
                
                <div className="exam-form">
                    <div className="form-group">
                      <h2 className="exam-title">GIẢ LẬP THI NGHIỆP VỤ</h2>
                        
                        <select 
                            value={selectedExam} 
                            onChange={(e) => {
                                setSelectedExam(e.target.value);
                                setError('');
                            }}
                        >

                            <option value="">Chọn đề thi...</option>
                            {examTypes.map(exam => (
                                <option key={exam.id} value={exam.id}>
                                    {exam.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    {error && <div className="error-message">{error}</div>}

                    {selectedExamData && (
                        <div className="exam-details">
                            <h3>Thông tin đề thi:</h3>
                            <div className="exam-info">
                                <div>Thời gian: {selectedExamData.time_limit / 60} phút</div>
                                <div>Tổng số câu: {selectedExamData.total_questions} câu</div>
                                <div className="exam-structure">
                                    <strong>Cấu trúc đề:</strong>
                                    <ul>
                                        {selectedExamData.structure.map((part, index) => (
                                            <li key={index}>
                                                {part.category_name}: {part.question_count} câu
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}

                    <button 
                        className="start-exam-button"
                        onClick={handleStartExam}
                        disabled={!selectedExam}
                    >
                        Bắt đầu thi
                    </button>
                </div>
            </main>
            
        </div>
    );
}

export default ExamSelection;