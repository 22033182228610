import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../styles/manage-users.css';


function ManageUsers() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [newUser, setNewUser] = useState({
        username: '',
        name: '',
        email: '',
        password: '',
        is_admin: 0
    });
    const [alertModal, setAlertModal] = useState({ show: false, content: '' });
    const [message, setMessage] = useState('');

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
           const response = await fetch(`${apiUrl}/users.php`);
            const data = await response.json();
            if (data.status === 'success') {
                setUsers(data.users);
            } else {
                setMessage('Không thể tải danh sách người dùng');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const handleAddUser = async (e) => {
        e.preventDefault();
        try {
           const response = await fetch(`${apiUrl}/users.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newUser),
            });
            const data = await response.json();
            if (data.status === 'success') {
                fetchUsers();
                setShowModal(false);
                setNewUser({
                    username: '',
                    name: '',
                    email: '',
                    password: '',
                    is_admin: 0
                });
                showAlertModal('Thêm người dùng thành công');
            } else {
                setMessage(data.message || 'Lỗi khi thêm người dùng');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const handleEditUser = async (user) => {
        const newName = prompt('Nhập tên mới:', user.name);
        if (newName) {
            try {
                const response = await fetch(`${apiUrl}/users.php`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id: user.id, name: newName }),
                });
                const data = await response.json();
                if (data.status === 'success') {
                    fetchUsers();
                    showAlertModal('Cập nhật người dùng thành công');
                } else {
                    setMessage(data.message || 'Lỗi khi cập nhật người dùng');
                }
            } catch (error) {
                setMessage('Lỗi kết nối server');
            }
        }
    };

    const handleDeleteUser = async (id) => {
        setAlertModal({
            show: true,
            content: (
                <>
                    <p>Bạn có chắc chắn muốn xóa người dùng này?</p>
                    <div className="modal-actions">
                        <button
                            onClick={async () => {
                                try {
                                    const response = await fetch(`${apiUrl}/users.php`, {
                                        method: 'DELETE',
                                        headers: { 'Content-Type': 'application/json' },
                                        body: JSON.stringify({ id }),
                                    });
                                    const data = await response.json();
                                    if (data.status === 'success') {
                                        fetchUsers();
                                        showAlertModal('Xóa người dùng thành công');
                                    } else {
                                        setMessage(data.message || 'Lỗi khi xóa người dùng');
                                    }
                                } catch (error) {
                                    setMessage('Lỗi kết nối server');
                                } finally {
                                    setAlertModal({ show: false, content: '' });
                                }
                            }}
                            className="btn-primary"
                        >
                            Xóa
                        </button>
                        <button onClick={() => setAlertModal({ show: false, content: '' })} className="btn-secondary">
                            Hủy
                        </button>
                    </div>
                </>
            ),
        });
    };

    const handleResetPassword = async (user) => {
        try {
            const response = await fetch(`${apiUrl}/users.php`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: user.id, password: 'agribank@123' }),
            });
            const data = await response.json();
            if (data.status === 'success') {
                showAlertModal(`Mật khẩu của ${user.username} đã được đặt lại thành công!`);
            } else {
                setMessage(data.message || 'Lỗi khi đặt lại mật khẩu');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const showAlertModal = (content) => {
        setAlertModal({ show: true, content: <p>{content}</p> });
        setTimeout(() => setAlertModal({ show: false, content: '' }), 3000);
    };

    const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.username.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="manage-users-page">
            <Header />
            <main className="dashboard-container">
                <h2 className="dashboard-title">Quản lý người dùng</h2>
                <Link to="/admin" className="back-to-dashboard">← Trở về Dashboard</Link>
                
               <div className="search-container">
    <input
        type="text"
        className="search-input"
        placeholder="Tìm kiếm theo tên, email hoặc username..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
    />
    <button onClick={() => setShowModal(true)} className="btn-add-new">
        Thêm
    </button>
</div>

                {message && <p className="message">{message}</p>}

                <div className="table-container">
                    <table className="users-table">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Tên người dùng</th>
                                <th>Email</th>
                                <th>Username</th>
                                <th>Quyền</th>
                                <th>Ngày tạo</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.username}</td>
                                    <td>{user.is_admin ? 'Quản trị viên' : 'Người dùng'}</td>
                                    <td>{new Date(user.created_at).toLocaleDateString('vi-VN')}</td>
                                    <td className="action-buttons">
                                        <button
                                            className="btn-edit"
                                            onClick={() => handleEditUser(user)}
                                            title="Chỉnh sửa"
                                        >
                                            Sửa
                                        </button>
                                        <button
                                            className="btn-reset"
                                            onClick={() => handleResetPassword(user)}
                                            title="Đặt lại mật khẩu"
                                        >
                                            Reset
                                        </button>
                                        <button
                                            className="btn-delete"
                                            onClick={() => handleDeleteUser(user.id)}
                                            title="Xóa người dùng"
                                        >
                                            Xóa
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </main>

            {showModal && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Thêm người dùng mới</h3>
                        <form onSubmit={handleAddUser} className="user-form">
                            <div className="form-group">
                                <label htmlFor="username">Tên đăng nhập *</label>
                                <input
                                    id="username"
                                    type="text"
                                    placeholder="Nhập tên đăng nhập"
                                    value={newUser.username}
                                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                                    required
                                    maxLength={50}
                                />
                                <small>Tối đa 50 ký tự, không được trùng lặp</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="name">Họ và tên *</label>
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Nhập họ và tên"
                                    value={newUser.name}
                                    onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
                                    required
                                    maxLength={100}
                                />
                                <small>Tối đa 100 ký tự</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="email">Email *</label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Nhập địa chỉ email"
                                    value={newUser.email}
                                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                                    required
                                    maxLength={100}
                                />
                                <small>Tối đa 100 ký tự, không được trùng lặp</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="password">Mật khẩu *</label>
                                <input
                                    id="password"
                                    type="password"
                                    placeholder="Nhập mật khẩu"
                                    value={newUser.password}
                                    onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                                    required
                                    minLength={6}
                                />
                                <small>Tối thiểu 6 ký tự</small>
                            </div>

                            <div className="form-group">
                                <label htmlFor="is_admin">Quyền hạn *</label>
                                <select
                                    id="is_admin"
                                    value={newUser.is_admin}
                                    onChange={(e) => setNewUser({ ...newUser, is_admin: Number(e.target.value) })}
                                    required
                                >
                                    <option value={0}>Người dùng</option>
                                    <option value={1}>Quản trị viên</option>
                                </select>
                            </div>

                            <div className="modal-actions">
                                <button type="submit" className="btn-primary">
                                    Thêm người dùng
                                </button>
                                <button
                                    type="button"
                                    className="btn-secondary"
                                    onClick={() => {
                                        setShowModal(false);
                                        setNewUser({
                                            username: '',
                                            name: '',
                                            email: '',
                                            password: '',
                                            is_admin: 0
                                        });
                                    }}
                                >
                                    Hủy
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {alertModal.show && (
                <div className="modal-overlay">
                    <div className="modal alert-modal">
                        {alertModal.content}
                    </div>
                </div>
            )}

            <Footer />
        </div>
    );
}

export default ManageUsers;