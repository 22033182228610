import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';

import '../../styles/quiz.css';

function Quiz() {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const categoryId = queryParams.get('category');
    const questionCount = parseInt(queryParams.get('count'), 10);
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({});
    const [showResult, setShowResult] = useState(false);   
    const [quizDuration, setQuizDuration] = useState(0);
    const [startTime, setStartTime] = useState(new Date());


    const handleRetry = () => {
    // Reset all states
    setQuestions([]);
    setCurrentQuestionIndex(0);
    setUserAnswers({});
    setShowResult(false);
    setQuizDuration(0);
    setStartTime(new Date());
    // Fetch questions again
    fetchQuestions();
};

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/get_random_questions.php?category=${categoryId}&count=${questionCount}`);
        const data = await response.json();
        if (data.status === 'success') {
            const processedQuestions = data.questions.map(question => {
                // Lưu đáp án đúng trước khi xáo trộn
                const correctAnswer = question[`answer${question.correct_answer}`];
                
                // Tạo mảng đáp án
                let options = [
                    question.answer1, 
                    question.answer2, 
                    question.answer3, 
                    question.answer4
                ].filter(answer => answer && answer.trim() !== '');

                // Chỉ xáo trộn nếu cần
                if (question.shuffle_answers === 1) {
                    options = shuffleArray(options);
                }

                return {
                    ...question,
                    options,
                    correct_answer: correctAnswer // Sử dụng đáp án đã lưu
                };
            });
            setQuestions(processedQuestions);
        } else {
            alert('Không tìm thấy câu hỏi.');
            navigate('/home');
        }
    } catch (error) {
        alert('Lỗi kết nối server.');
    }
};

    const shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const handleOptionSelect = (option) => {
        if (userAnswers[currentQuestionIndex]) return;

        const currentQuestion = questions[currentQuestionIndex];
        const isCorrect = option === currentQuestion.correct_answer;

        setUserAnswers(prev => ({
            ...prev,
            [currentQuestionIndex]: {
                selected: option,
                isCorrect: isCorrect
            }
        }));
    };

    const handleSubmit = () => {
        if (Object.keys(userAnswers).length < questions.length) {
            if (!confirm('Bạn chưa hoàn thành tất cả câu hỏi. Bạn có chắc muốn nộp bài?')) {
                return;
            }
        }
        const endTime = new Date();
        const duration = Math.floor((endTime - startTime) / 1000);
        setQuizDuration(duration);
        setShowResult(true);
    };

    const renderCurrentQuestion = () => {
        if (!questions.length) return null;
        
        const currentQuestion = questions[currentQuestionIndex];
        const currentAnswer = userAnswers[currentQuestionIndex];

        return (
            <div className="quiz-question__container">
                <div className="quiz-question__header">
                    <div className="quiz-question__number">
                        Câu {currentQuestionIndex + 1}/{questions.length}
                    </div>
                    <div className="quiz-question__text">
                        {currentQuestion.question}
                    </div>
                </div>

                <div className="quiz-options__container">
                    {currentQuestion.options.map((option, index) => (
                        <div
                            key={index}
                            className={`quiz-option__item ${
                                currentAnswer?.selected === option 
                                    ? (currentAnswer.isCorrect ? 'quiz-option--correct' : 'quiz-option--incorrect')
                                    : (currentAnswer && option === currentQuestion.correct_answer ? 'quiz-option--correct' : '')
                            }`}
                            onClick={() => !currentAnswer && handleOptionSelect(option)}
                        >
                            {option}
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    const renderQuizResult = () => {
        const correctCount = Object.values(userAnswers).filter(answer => answer.isCorrect).length;
        const minutes = Math.floor(quizDuration / 60);
        const seconds = quizDuration % 60;

        return (
            <>
                <div className="quiz-result__container">
                    <div className="quiz-result__header">
                        <h2 className="quiz-result__title">Kết quả bài thi</h2>
                        <div className="quiz-result__summary">
                            <div>Số câu đúng: {correctCount}/{questions.length}</div>
                            <div>Thời gian: {minutes} phút {seconds} giây</div>
                        </div>
                    </div>

                    {questions.map((question, index) => (
                        <div key={index} className="quiz-review__item">
                            <div className="quiz-review__header">
                                <div className="quiz-review__number">
                                    Câu {index + 1}
                                    <span className={userAnswers[index]?.isCorrect ? "quiz-status--correct" : "quiz-status--incorrect"}>
                                        {userAnswers[index]?.isCorrect ? "✓ Đúng" : "✗ Sai"}
                                    </span>
                                </div>
                                <div className="quiz-review__question">{question.question}</div>
                            </div>

                            <div className="quiz-options__container">
                                {question.options.map((option, optIndex) => (
                                    <div
                                        key={optIndex}
                                        className={`quiz-option__item ${
                                            userAnswers[index]?.selected === option
                                                ? (userAnswers[index]?.isCorrect ? 'quiz-option--correct' : 'quiz-option--incorrect')
                                                : option === question.correct_answer ? 'quiz-option--correct' : ''
                                        }`}
                                    >
                                        {option}
                                    </div>
                                ))}
                            </div>

                            {!userAnswers[index]?.isCorrect && (
                                <div className="quiz-explanation">
                                    <strong>Giải thích:</strong> {question.explanation}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                <div className="quiz-actions">
                    <button 
    className="quiz-button quiz-button--retry" 
    onClick={handleRetry}
>
    Làm lại
</button>
                    <button className="quiz-button quiz-button--home" onClick={() => navigate('/home')}>
                        Về trang chủ
                    </button>
                </div>
            </>
        );
    };

return (
    <div className="quiz-page">
        <Header />
        <div className="quiz-content">
            {showResult ? renderQuizResult() : renderCurrentQuestion()}
        </div>
        {!showResult && (
            <div className="quiz-navigation">
                <button 
                    className="quiz-button quiz-button--nav"
                    onClick={() => setCurrentQuestionIndex(prev => prev - 1)}
                    disabled={currentQuestionIndex === 0}
                >
                    Câu trước
                </button>
                <button 
                    className="quiz-button quiz-button--submit"
                    onClick={handleSubmit}
                >
                    Nộp bài
                </button>
                <button 
                    className="quiz-button quiz-button--nav"
                    onClick={() => setCurrentQuestionIndex(prev => prev + 1)}
                    disabled={currentQuestionIndex === questions.length - 1}
                >
                    Câu sau
                </button>
            </div>
        )}
    </div>
);
}

export default Quiz;