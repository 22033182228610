// frontend/src/components/common/Header.js
import React from 'react';
import '../../styles/layout.css';

function Header() {
    return (
        <header className="header">
           
        </header>
    );
}

export default Header;
