import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../../styles/exam-quiz.css';

function ExamQuiz() {
    // Router hooks
    const { examId } = useParams();
    const navigate = useNavigate();

    // State management
    const [examData, setExamData] = useState(null);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState({});
    const [timeLeft, setTimeLeft] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [isQuestionListOpen, setIsQuestionListOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [score, setScore] = useState(null);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [examStartTime] = useState(Date.now());
    const [completionTime, setCompletionTime] = useState(null);
    
    // Environment variables
    const apiUrl = process.env.REACT_APP_API_URL;

   // Khởi tạo bài thi
const initExam = async () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
        setError('Phiên làm việc hết hạn, vui lòng đăng nhập lại.');
        navigate('/login');
        return;
    }

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/start_exam.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ exam_config_id: examId })
        });

        const data = await response.json();
        console.log('Init Exam Response:', data); // Log kiểm tra

        if (data.status === 'success') {
            setExamData({
                ...data.examData,
                exam_id: data.examData.exam_id
            });
            setTimeLeft(data.examData.time_limit);
            setLoading(false);
        } else {
            setError(data.message || 'Không thể bắt đầu bài thi');
            setLoading(false);
        }
    } catch (error) {
        console.error('Init Error:', error);
        setError('Lỗi kết nối server');
        setLoading(false);
    }
};

    // Lưu tiến trình làm bài
    const saveProgressToServer = useCallback(async () => {
        const token = localStorage.getItem('authToken');
        if (!token || !examData) return;

        try {
            await fetch(`${process.env.REACT_APP_API_URL}/save_progress.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    exam_id: examData.exam_id,
                    answers: answers,
                    time_left: timeLeft,
                })
            });
        } catch (error) {
            console.error('Lỗi lưu tiến trình:', error);
        }
    }, [apiUrl, examData, answers, timeLeft]);

    // Lưu tiến trình vào localStorage
    const saveToLocalStorage = useCallback(() => {
        localStorage.setItem(`exam-${examId}`, JSON.stringify({
            savedData: examData,
            savedAnswers: answers,
            savedTime: timeLeft,
            savedQuestion: currentQuestion,
            hasSubmitted: hasSubmitted,
            savedScore: score,
        }));
    }, [examId, examData, answers, timeLeft, currentQuestion, hasSubmitted, score]);

    // Effect để load dữ liệu ban đầu
    useEffect(() => {
        const fetchExamData = async () => {
            const savedExam = localStorage.getItem(`exam-${examId}`);
            if (savedExam) {
                const { 
                    savedData, 
                    savedAnswers, 
                    savedTime, 
                    savedQuestion, 
                    hasSubmitted, 
                    savedScore 
                } = JSON.parse(savedExam);
                
                setExamData(savedData);
                setAnswers(savedAnswers);
                setTimeLeft(savedTime);
                setCurrentQuestion(savedQuestion);
                setHasSubmitted(hasSubmitted);
                setScore(savedScore);
                setLoading(false);

                if (hasSubmitted) {
                    setShowModal(true);
                }
            } else {
                await initExam();
            }
        };

        fetchExamData();
    }, [examId]);

    // Effect để quản lý timer và auto-save
    useEffect(() => {
        let timer;
        let saveTimer;

        if (examData && timeLeft > 0 && !hasSubmitted) {
            // Timer để cập nhật thời gian mỗi giây
            timer = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 1) {
                        clearInterval(timer);
                        clearInterval(saveTimer);
                        handleSubmitExam();
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);

            // Timer để lưu progress định kỳ
            saveTimer = setInterval(() => {
                saveProgressToServer();
                saveToLocalStorage();
            }, 60000);
        }

        return () => {
            clearInterval(timer);
            clearInterval(saveTimer);
        };
    }, [timeLeft, examData, hasSubmitted, saveProgressToServer, saveToLocalStorage]);

    // Định dạng thời gian
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    // Xử lý chọn đáp án
    const handleAnswerSelect = useCallback((questionId, answer) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: answer
        }));
    }, []);

    // Xử lý nộp bài
const handleSubmitExam = async () => {
    const token = localStorage.getItem('authToken');
    
    // Kiểm tra câu chưa trả lời
    const unansweredCount = examData.questions.length - Object.keys(answers).length;
    
    if (unansweredCount > 0) {
        const confirmed = window.confirm(
            `Bạn còn ${unansweredCount} câu chưa trả lời. Bạn có chắc chắn muốn nộp bài?`
        );
        if (!confirmed) return;
    }

    if (!token) {
        setError('Vui lòng đăng nhập để tiếp tục.');
        navigate('/login');
        return;
    }

    // Kiểm tra exam_id
    if (!examData || !examData.exam_id) {
        setError('Không tìm thấy thông tin bài thi');
        return;
    }

    try {
        const timeTaken = Math.floor((Date.now() - examStartTime) / 1000);
        setCompletionTime(timeTaken);

        const submitData = {
            exam_id: parseInt(examData.exam_id),
            answers: answers,
            completion_time: timeTaken
        };
        
        console.log('Submit Data:', submitData); // Log kiểm tra

        const response = await fetch(`${process.env.REACT_APP_API_URL}/submit_exam.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(submitData)
        });

        const data = await response.json();
        console.log('Submit Response:', data); // Log kiểm tra

        if (data.status === 'success') {
            setScore(data.data.score);
            setShowModal(true);
            setHasSubmitted(true);
            localStorage.removeItem(`exam-${examId}`);
        } else {
            setError(data.message || 'Lỗi khi nộp bài');
        }
    } catch (error) {
        console.error('Submit Error:', error);
        setError('Lỗi kết nối server');
    }
};

    // Xử lý đóng modal kết quả
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
        navigate('/home');
    }, [navigate]);

    // Loading states
    if (loading) return <div className="loading">Đang tải...</div>;
    if (error) return <div className="error">{error}</div>;
    if (!examData) return <div className="error">Không tìm thấy đề thi</div>;

    // Lấy dữ liệu câu hỏi hiện tại
    const currentQuestionData = examData.questions[currentQuestion];
    const answeredCount = Object.keys(answers).length;

    return (
        <div className="exam-quiz-page">
            {/* Header section */}
            <div className="exam-header">
                <div className="timer">⏱ {formatTime(timeLeft)}</div>
                <div className="exam-progress">
                    Đã làm: {answeredCount}/{examData.questions.length} câu
                </div>
            </div>

            {/* Main content */}
            <main className="exam-container">
                <div className="question-section">
                    <div className="question-header">
                        <h3>Câu {currentQuestion + 1}/{examData.questions.length}</h3>
                    </div>

                    <div className="question-content">
                        {currentQuestionData.question}
                    </div>

                    <div className="answers-list">
                        {['answer1', 'answer2', 'answer3', 'answer4'].map((key, index) => (
                            currentQuestionData[key] && (
                                <label
                                    key={key}
                                    className={`answer-option ${
                                        answers[currentQuestionData.id] === (index + 1).toString() 
                                        ? 'selected' 
                                        : ''
                                    }`}
                                >
                                    <input
                                        type="radio"
                                        name={`question-${currentQuestionData.id}`}
                                        value={index + 1}
                                        checked={answers[currentQuestionData.id] === (index + 1).toString()}
                                        onChange={() => handleAnswerSelect(currentQuestionData.id, (index + 1).toString())}
                                    />
                                    <span className="answer-text">{currentQuestionData[key]}</span>
                                </label>
                            )
                        ))}
                    </div>
                </div>

                {/* Navigation buttons */}
                <div className="button-navigation">
                    <button
                        className="nav-button"
                        onClick={() => setCurrentQuestion(prev => prev - 1)}
                        disabled={currentQuestion === 0}
                    >
                        ← Câu trước
                    </button>
                    <button
                        className="nav-button"
                        onClick={() => setCurrentQuestion(prev => prev + 1)}
                        disabled={currentQuestion === examData.questions.length - 1}
                    >
                        Câu tiếp →
                    </button>
                </div>
            </main>

            {/* Footer section */}
            <div className="footer-container">
    <button
        className="toggle-button"
        onClick={() => setIsQuestionListOpen(prev => !prev)}
    >
        {isQuestionListOpen ? 'Thu gọn' : 'Danh sách'}
    </button>
    <button 
        className="submit-btn" 
        onClick={handleSubmitExam}
        disabled={hasSubmitted}
    >
        Nộp bài
    </button>
</div>

            {/* Question list overlay */}
            {isQuestionListOpen && (
                <div className="question-list">
                    <div className="question-grid">
                        {examData.questions.map((question, idx) => (
                            <button
                                key={idx}
                                className={`grid-item ${
                                    currentQuestion === idx ? 'active' : ''
                                } ${
                                    answers[question.id] ? 'answered' : ''
                                }`}
                                onClick={() => setCurrentQuestion(idx)}
                            >
                                {idx + 1}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            {/* Results modal */}
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h2>Kết quả bài thi</h2>
                        <div className="result-info">
                            <div className="result-item">
                                <span className="label">Điểm số:</span>
                                <span className="value score">{score}</span>
                            </div>
                            
                            <div className="result-item">
                                <span className="label">Số câu đã làm:</span>
                                <span className="value">
                                    {Object.keys(answers).length}/{examData.questions.length}
                                </span>
                            </div>

                            <div className="result-item">
                                <span className="label">Thời gian làm bài:</span>
                                <span className="value">
                                    {Math.floor(completionTime / 60)} phút {completionTime % 60} giây
                                </span>
                            </div>
                        </div>
                        <button onClick={handleCloseModal}>Đóng</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ExamQuiz;