import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const isAuthenticated = () => {
    return !!localStorage.getItem('authToken'); // Kiểm tra token
};

const isGuestUser = () => {
    try {
        const user = JSON.parse(localStorage.getItem('user'));
        return user && user.isGuest;
    } catch {
        return false;
    }
};

const getRole = () => {
    return localStorage.getItem('role');
};

const PrivateRoute = ({ role }) => {
    // Lấy đường dẫn hiện tại
    const currentPath = window.location.pathname;

    // Danh sách các đường dẫn cho phép tài khoản khách truy cập
    const guestAllowedPaths = ['/home', '/study', '/author'];

    // Kiểm tra xem người dùng hiện tại có phải là khách không
    const isGuest = isGuestUser();

    // Nếu là route admin
    if (role === 'admin') {
        if (!isAuthenticated() || getRole() !== 'admin') {
            return <Navigate to="/admin-login" />;
        }
        return <Outlet />;
    }

    // Nếu là khách
    if (isGuest) {
        // Chỉ cho phép truy cập các đường dẫn được phép
        if (!guestAllowedPaths.includes(currentPath)) {
            return <Navigate to="/home" />;
        }
        return <Outlet />;
    }

    // Nếu chưa đăng nhập và không phải khách
    if (!isAuthenticated() && !isGuest) {
        return <Navigate to="/" />;
    }

    // Cho phép truy cập tất cả các route nếu đã đăng nhập
    return <Outlet />;
};

export default PrivateRoute;