import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import { Link } from 'react-router-dom';
import '../../styles/Leaderboard.css';



function Leaderboard() {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
const apiUrl = process.env.REACT_APP_API_URL; // Lấy URL từ biến môi trường
    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/leaderboard.php`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                if (data.status === 'success') {
                    setLeaderboard(data.leaderboard);
                } else {
                    setError(data.message || 'Lỗi khi lấy bảng xếp hạng.');
                }
            } catch (error) {
                setError('Lỗi kết nối server: ' + error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLeaderboard();
    }, []);

    if (loading) {
        return (
            <div>
                <Header />
                <div className="leaderboard-container">
                    <p>Đang tải bảng xếp hạng...</p>
                </div>
               
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="leaderboard-container">
                    <div className="error-message">{error}</div>
                </div>
                           </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="leaderboard-container">
                <h2>Bảng Xếp Hạng</h2>
                <Link to="/home" className="back-to-dashboard">
                    ← Trở về
                </Link>
                <table>
                    <thead>
                        <tr>
                            <th>Hạng</th>
                            <th>Tên người dùng</th>
                            <th>Điểm</th>
                            <th>Thời gian làm bài</th>
                        </tr>
                    </thead>
                   <tbody>
    {leaderboard.map((user, index) => (
        <tr key={`${user.user_id}-${index}`}>
            <td>
                {index < 3 ? (
                    <img
                        src={process.env.PUBLIC_URL + `/images/00${index + 1}.png`}
                        // hoặc src={`${process.env.PUBLIC_URL}/images/00${index + 1}.png`}
                        alt={`Hạng ${index + 1}`}
                        className="rank-icon"
                    />
                ) : (
                    index + 1
                )}
            </td>
            <td>{user.username}</td>
            <td>{user.score}</td>
            <td>{user.duration} giây</td>
        </tr>
    ))}
</tbody>
                </table>
            </div>
        
        </div>
    );
}

export default Leaderboard;
