import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header';

import '../../styles/admin-dashboard.css';

function AdminDashboard() {
    const menuItems = [
        {
            path: '/admin/manage-users',
            title: 'Quản lý người dùng'
        },
        {
            path: '/admin/manage-questions',
            title: 'Quản lý câu hỏi'
        },
        {
            path: '/admin/manage-categories',
            title: 'Quản lý danh mục'
        },
               
        {
            path: '/admin/manage-exam-configs',
            title: 'Quản lý đề thi'
        }
    ];

    return (
        <div className="admin-dashboard-page">
            <Header />
            
            <main className="dashboard-container">
                <h2 className="dashboard-title">Admin Panel</h2>
                
                <div className="admin-menu-container">
                    {menuItems.map((item, index) => (
                        <Link 
                            key={index} 
                            to={item.path} 
                            className="admin-menu-item"
                        >
                            <div className="admin-menu-content">
                                <h3>{item.title}</h3>
                            </div>
                        </Link>
                    ))}
                </div>
            </main>

            
        </div>
    );
}

export default AdminDashboard;