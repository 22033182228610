import React, { useState, useEffect } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../styles/manage-exam-configs.css';
import { Link } from 'react-router-dom';

function ManageExamConfigs() {
    // State declarations
    const [examConfigs, setExamConfigs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showConfigModal, setShowConfigModal] = useState(false);
    const [editingConfig, setEditingConfig] = useState(null);
    const [newConfig, setNewConfig] = useState({
        name: '',
        description: '',
        details: [{ category_id: '', question_count: 0 }]
    });
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    

    // Effects
    useEffect(() => {
        fetchExamConfigs();
        fetchCategories();
    }, []);

    // Helper Functions
    const showMessage = (msg, type = 'error') => {
        setMessage(msg);
        setMessageType(type);
        setTimeout(() => {
            setMessage('');
            setMessageType('');
        }, 3000);
    };

    const resetForm = () => {
        setNewConfig({
            name: '',
            description: '',
            details: [{ category_id: '', question_count: 0 }]
        });
        setEditingConfig(null);
        setShowConfigModal(false);
    };

    // API Calls
const fetchCategories = async () => {
    try {
        const response = await fetch(`${apiUrl}/categories.php?type=categories`); // Sử dụng apiUrl từ biến môi trường
        const data = await response.json();
        if (data.status === 'success') {
            setCategories(data.categories);
        } else {
            showMessage('Lỗi khi tải danh mục');
        }
    } catch (error) {
        showMessage('Lỗi kết nối server');
    }
};

const fetchExamConfigs = async () => {
    try {
        const response = await fetch(`${apiUrl}/exam_configs.php`);  // Sử dụng apiUrl từ biến môi trường
        const data = await response.json();
        if (data.status === 'success') {
            setExamConfigs(data.configs);
        } else {
            showMessage(data.message || 'Lỗi khi tải cấu hình đề thi');
        }
    } catch (error) {
        showMessage('Lỗi kết nối server');
    }
};

    // Form Handlers
    const handleDetailChange = (index, field, value) => {
        setNewConfig(prev => ({
            ...prev,
            details: prev.details.map((detail, i) => 
                i === index ? { ...detail, [field]: value } : detail
            )
        }));
    };

    const handleAddDetail = () => {
        setNewConfig(prev => ({
            ...prev,
            details: [...prev.details, { category_id: '', question_count: 0 }]
        }));
    };

    const handleRemoveDetail = (index) => {
        if (newConfig.details.length === 1) {
            showMessage('Phải có ít nhất một danh mục');
            return;
        }
        setNewConfig(prev => ({
            ...prev,
            details: prev.details.filter((_, i) => i !== index)
        }));
    };

    // Validation
    const validateQuestionCount = (details) => {
        for (const detail of details) {
            const category = categories.find(c => c.id.toString() === detail.category_id.toString());
            if (!category) continue;
            
            if (detail.question_count > category.total_questions) {
                showMessage(`Danh mục ${category.name} chỉ có ${category.total_questions} câu hỏi. Không thể chọn ${detail.question_count} câu.`);
                return false;
            }
        }
        return true;
    };

    // CRUD Operations
const handleSubmit = async (e) => {
    e.preventDefault();
    
    const isValid = newConfig.details.every(detail => 
        detail.category_id && detail.question_count > 0
    );

    if (!isValid) {
        showMessage('Vui lòng điền đầy đủ thông tin cho tất cả các danh mục');
        return;
    }

    if (!validateQuestionCount(newConfig.details)) {
        return;
    }

    const config = editingConfig ? { ...newConfig, id: editingConfig.id } : newConfig;
    
    try {
        const response = await fetch(`${apiUrl}/exam_configs.php`, {  // Sử dụng apiUrl từ biến môi trường
            method: editingConfig ? 'PUT' : 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(config)
        });
        
        const data = await response.json();
        
        if (data.status === 'success') {
            fetchExamConfigs();
            resetForm();
            showMessage(data.message, 'success');
        } else {
            showMessage(data.message || 'Có lỗi xảy ra');
        }
    } catch (error) {
        showMessage('Lỗi kết nối server');
    }
};

    const handleEdit = (config) => {
        setEditingConfig(config);
        setNewConfig({
            name: config.name,
            description: config.description || '',
            details: config.details.map(detail => ({
                category_id: detail.category_id.toString(),
                question_count: parseInt(detail.question_count)
            }))
        });
        setShowConfigModal(true);
    };

    const handleDelete = async (id) => {
    if (!window.confirm('Bạn có chắc chắn muốn xóa cấu hình này?')) return;
    
    try {
        const response = await fetch(`${apiUrl}/exam_configs.php`, {  // Sử dụng apiUrl từ biến môi trường
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id })
        });
        
        const data = await response.json();
        
        if (data.status === 'success') {
            fetchExamConfigs();
            showMessage('Xóa cấu hình thành công', 'success');
        } else {
            showMessage(data.message || 'Lỗi khi xóa cấu hình');
        }
    } catch (error) {
        showMessage('Lỗi kết nối server');
    }
};

    // Render Methods
    const renderConfigDetails = (details) => {
        const total = details.reduce((sum, detail) => sum + parseInt(detail.question_count), 0);
        return details.map((detail, index) => (
            <li key={index}>
                {detail.category_name}: {detail.question_count} câu
                ({((detail.question_count / total) * 100).toFixed(1)}%)
            </li>
        ));
    };

    return (
        <div className="manage-exam-configs-page">
            <Header />
            <main className="dashboard-container">
                <h2>Thiết lập cấu hình đề thi</h2>
                <Link to="/admin" className="back-to-dashboard">← Trở về Dashboard</Link>


                {message && (
                    <div className={`alert alert-${messageType}`}>
                        {message}
                    </div>
                )}

                <div className="actions">
                    <button 
                        className="btn btn-primary"
                        onClick={() => setShowConfigModal(true)}
                    >
                        Tạo cấu hình mới
                    </button>
                </div>

                <div className="configs-list">
                    <table>
                        <thead>
                            <tr>
                                <th>Tên cấu hình</th>
                                <th>Mô tả</th>
                                <th>Cấu trúc đề thi</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            {examConfigs.length > 0 ? (
                                examConfigs.map(config => (
                                    <tr key={config.id}>
                                        <td>{config.name}</td>
                                        <td>{config.description || 'Không có mô tả'}</td>
                                        <td>
                                            <ul>
                                                {renderConfigDetails(config.details)}
                                                <li className="total">
                                                    Tổng số câu: {config.details.reduce(
                                                        (sum, detail) => sum + parseInt(detail.question_count), 
                                                        0
                                                    )}
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-edit"
                                                onClick={() => handleEdit(config)}
                                            >
                                                Sửa
                                            </button>
                                            <button
                                                className="btn btn-delete"
                                                onClick={() => handleDelete(config.id)}
                                            >
                                                Xóa
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        Chưa có cấu hình nào
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {showConfigModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <h3>{editingConfig ? 'Sửa cấu hình' : 'Tạo cấu hình mới'}</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label>Tên cấu hình:</label>
                                    <input
                                        type="text"
                                        value={newConfig.name}
                                        onChange={(e) => setNewConfig({
                                            ...newConfig,
                                            name: e.target.value
                                        })}
                                        required
                                        placeholder="Nhập tên cấu hình"
                                    />
                                </div>

                               

                                <div className="details-section">
                                    <h4>Cấu trúc đề thi</h4>
                                    {newConfig.details.map((detail, index) => {
                                        const selectedCategory = categories.find(
                                            c => c.id.toString() === detail.category_id
                                        );
                                        const totalQuestions = selectedCategory ? selectedCategory.total_questions : 0;
                                        
                                        return (
                                            <div key={index} className="detail-item">
                                                <div className="detail-inputs">
                                                    <select
                                                        value={detail.category_id}
                                                        onChange={(e) => handleDetailChange(index, 'category_id', e.target.value)}
                                                        required
                                                    >
                                                        <option value="">Chọn danh mục</option>
                                                        {categories.map(cat => (
                                                            <option 
                                                                key={cat.id} 
                                                                value={cat.id}
                                                                disabled={newConfig.details.some(
                                                                    (d, i) => i !== index && d.category_id === cat.id.toString()
                                                                )}
                                                            >
                                                                {cat.name} ({cat.total_questions} câu)
                                                            </option>
                                                        ))}
                                                    </select>

                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max={totalQuestions}
                                                        value={detail.question_count}
                                                        onChange={(e) => handleDetailChange(
                                                            index,
                                                            'question_count',
                                                            parseInt(e.target.value) || 0
                                                        )}
                                                        required
                                                    />

                                                    {selectedCategory && (
                                                        <span className="percentage">
                                                            ({(detail.question_count / newConfig.details.reduce(
                                                                (sum, d) => sum + (parseInt(d.question_count) || 0), 
                                                                0
                                                            ) * 100).toFixed(1)}%)
                                                        </span>
                                                    )}

                                                    <button
                                                        type="button"
                                                        className="btn btn-remove"
                                                        onClick={() => handleRemoveDetail(index)}
                                                    >
                                                        Xóa
                                                    </button>
                                                </div>

                                                {selectedCategory && (
                                                    <div className="detail-info">
                                                        Số câu có sẵn: {totalQuestions} câu
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    })}

                                    <div className="total-questions">
                                        Tổng số câu: {newConfig.details.reduce(
                                            (sum, detail) => sum + (parseInt(detail.question_count) || 0),
                                            0
                                        )}
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-add"
                                        onClick={handleAddDetail}
                                    >
                                        Thêm danh mục
                                    </button>
                                </div>

                                <div className="modal-actions">
                                    <button type="submit" className="btn btn-primary">
                                        {editingConfig ? 'Cập nhật' : 'Tạo mới'}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={resetForm}
                                    >
                                        Hủy
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
}

export default ManageExamConfigs;