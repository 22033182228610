import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import '../../styles/login.css';

function Login() {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleGuestLogin = () => {
    try {
        // Xóa dữ liệu cũ nếu có
        localStorage.clear();
        
        // Tạo dữ liệu guest mới
        const guestUser = {
            username: 'Khách',
            isGuest: true,
            id: Date.now() // Thêm id ngẫu nhiên
        };
        
        // Log để kiểm tra
        console.log('Attempting guest login with data:', guestUser);
        
        // Lưu vào localStorage
        localStorage.setItem('user', JSON.stringify(guestUser));
        
        // Log để xác nhận đã lưu
        console.log('Guest data saved to localStorage:', localStorage.getItem('user'));
        
        // Chuyển hướng ngay lập tức
        window.location.href = '/home';
        
    } catch (error) {
        console.error('Error during guest login:', error);
        setMessage('Có lỗi xảy ra khi đăng nhập khách');
    }
};

    const handleRegister = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setMessage('Mật khẩu xác nhận không khớp');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/register.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    name,
                    email,
                    password
                }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                setMessage('Đăng ký thành công');
                setIsLogin(true);
                setUsername('');
                setName('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
            } else {
                setMessage(data.message || 'Có lỗi xảy ra');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('Lỗi kết nối với server');
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/login.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.status === 'success') {
                setMessage('Đăng nhập thành công');
                localStorage.setItem('authToken', data.authToken);
                localStorage.setItem('user', JSON.stringify(data.user));
                navigate('/home');
            } else {
                setMessage(data.message || 'Tên đăng nhập hoặc mật khẩu không đúng');
            }
        } catch (error) {
            setMessage(`Lỗi: ${error.message}`);
        }
    };

    return (
        <div className="login-page">
            <div className="abstract-background"></div>
            <Header />
            <main className="login-container">
                <img src="/images/logo.png" alt="Agriquiz Logo" className="login-logo" />
                <p className="logo">
                    <span className="letter" style={{ color: '#ae1c3f' }}>A</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>G</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>R</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>I</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>Q</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>U</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>I</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>Z</span>
                    <span className="letter" style={{ color: '#ae1c3f' }}>!</span>
                </p>

                <div className="login-box">
                    <div className="tab-switch">
                        <button 
                            className={isLogin ? "active-tab" : ""}
                            onClick={() => setIsLogin(true)}
                        >
                            Đăng Nhập
                        </button>
                        <button 
                            className={!isLogin ? "active-tab" : ""}
                            onClick={() => setIsLogin(false)}
                        >
                            Đăng Ký
                        </button>
                    </div>
                    {isLogin ? (
                        <form className="login-form" onSubmit={handleLogin}>
                            <h2>ĐĂNG NHẬP</h2>
                            <input
                                type="text"
                                placeholder="Tên đăng nhập"
                                className="input-field"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Mật khẩu"
                                className="input-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="submit-button">Đăng Nhập</button>
                            <button 
                                type="button" 
                                className="guest-button"
                                onClick={handleGuestLogin}
                            >
                                Đăng nhập với tư cách khách
                            </button>
                            {message && <p className="message">{message}</p>}
                        </form>
                    ) : (
                        <form className="register-form" onSubmit={handleRegister}>
                            <h2>ĐĂNG KÝ</h2>
                            <input
                                type="text"
                                placeholder="Tên"
                                className="input-field"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Tên đăng nhập"
                                className="input-field"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                            <input
                                type="email"
                                placeholder="Email"
                                className="input-field"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Mật khẩu"
                                className="input-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <input
                                type="password"
                                placeholder="Xác nhận mật khẩu"
                                className="input-field"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                            <button type="submit" className="submit-button">Đăng Ký</button>
                            {message && <p className="message">{message}</p>}
                        </form>
                    )}
                </div>
            </main>
            <footer className="login-footer">
                <p>Agriquiz © 2024 by Phan Tiến</p>
            </footer>
        </div>
    );
}

export default Login;