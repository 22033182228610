import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header';

import '../../styles/study.css';
import { Link } from 'react-router-dom'; // Thêm Link để chuyển trang

function Study() {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [questionCount, setQuestionCount] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchCategories();
    }, []);

   const fetchCategories = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/categories.php`);
        const data = await response.json();
        if (data.status === 'success') {
            // Thiết lập danh sách danh mục với số lượng câu hỏi
            const categoriesWithCounts = data.categories.map(category => ({
                ...category,
                question_count: category.question_count // Số lượng câu hỏi
            }));
            setCategories(categoriesWithCounts);
        } else {
            alert('Không thể tải danh mục.');
        }
    } catch (error) {
        alert('Lỗi kết nối server.');
    }
};


    const handleStart = () => {
        if (!selectedCategory) {
            setError('Vui lòng chọn danh mục');
            return;
        }
        if (!questionCount || questionCount <= 0) {
            setError('Vui lòng nhập số lượng câu hỏi');
            return;
        }
        navigate(`/quiz?category=${selectedCategory}&count=${questionCount}`);
    };

   return (
    <div className="study-wrapper">
        <Header />
        <main className="study-main">
            
            <div className="study-content">
                <h2 className="study-heading">ÔN TẬP</h2>
                <Link to="/home" className="study-back-link">
                ← Trở về
            </Link> 
               
                <div className="study-input-group">

                    <label className="study-label">Chọn danh mục</label>
                    <select 
                        className="study-select"
                        value={selectedCategory} 
                        onChange={(e) => setSelectedCategory(e.target.value)}
                    >
                        <option value="">Chọn...</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name} ({category.question_count} câu)
                            </option>
                        ))}
                    </select>
                </div>
                <div className="study-input-group">
                    <label className="study-label">Số lượng câu hỏi</label>
                    <input 
                        type="number" 
                        className="study-input"
                        value={questionCount}
                        onChange={(e) => setQuestionCount(e.target.value)}
                        min="1"
                    />
                </div>
                {error && <div className="study-error">{error}</div>}
                <button className="study-submit" onClick={handleStart}>
                    Bắt đầu
                </button>
            </div>
        </main>
       
    </div>
);
}

export default Study;