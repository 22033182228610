import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../common/Header'; // Đảm bảo Header đã được tạo từ trước

import '../../styles/author.css'; // File CSS riêng cho trang tác giả

function Author() {
    return (
        <div className="author-page">
            <Header />
            <div className="author-container">
                <h2>Giới Thiệu Tác Giả</h2>
                <div className="author-content">
                    <p>Xin chào! Lời đầu tiên, tôi xin cảm ơn các bạn đã ghé và sử dụng ứng dụng.</p>
                    <p>Ứng dụng này được xây dựng nhằm giúp người dùng có thể ôn luyện và kiểm tra kiến thức một cách hiệu quả qua các bài thi trắc nghiệm.</p>
                    <p>Ứng dụng không được xây dựng với mục đích thương mại, nhưng nếu thấy có ích bạn có thể ủng hộ tác giả 1 ly cafe lấy động lực qua TK: <b>3611 205 088888</b></p>
                     <p>Về nội dung câu hỏi, có thể có lỗi chính tả, sai sót trong quá trình nhập liệu, và mình cũng xin phép không share bộ đề dưới bất kỳ hình thức nào, mong các bạn thông cảm.</p>
                    <p>Chúc các bạn học tốt và đạt được kết quả cao trong kỳ thi của mình!</p>
                </div>
                <Link to="/home" className="back-to-dashboard">
                    ← Trở về trang chủ
                </Link>
            </div>
           
        </div>
    );
}

export default Author;
