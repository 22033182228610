import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../styles/home.css';

function Home() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [isGuest, setIsGuest] = useState(false);

    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                setUsername(user.username);
                setIsGuest(user.isGuest || false);
            } else {
                // Nếu không có thông tin user, chuyển về trang login
                navigate('/', { replace: true });
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
            navigate('/', { replace: true });
        }
    }, [navigate]);

    const handleLogout = () => {
        // Xóa toàn bộ dữ liệu người dùng
        localStorage.clear();
        // Chuyển về trang login và thay thế history để không quay lại được
        navigate('/', { replace: true });
    };

    const renderMenuItem = (to, icon, title, description, guestDisabled = false) => {
        if (isGuest && guestDisabled) {
            return (
                <div className="menu-item disabled">
                    <div className="menu-content">
                        <img src={icon} alt={title} className="menu-icon" />
                        <h3 className="menu-title">{title}</h3>
                        <p className="menu-description">{description}</p>
                        <p className="guest-notice">Chỉ dành cho thành viên đăng ký</p>
                    </div>
                </div>
            );
        }

        return (
            <Link to={to} className="menu-item">
                <div className="menu-content">
                    <img src={icon} alt={title} className="menu-icon" />
                    <h3 className="menu-title">{title}</h3>
                    <p className="menu-description">{description}</p>
                </div>
            </Link>
        );
    };

    return (
        <div className="home-page">
            <header className="home-header">
                <h2>Xin chào, {username}!</h2>
                <button className="logout-button" onClick={handleLogout}>Đăng xuất</button>
            </header>
            <div className="menu-container">
                {renderMenuItem(
                    "/study",
                    "/images/01.png",
                    "Ôn tập",
                    "Ôn tập các nghiệp vụ",
                    false // Cho phép khách truy cập
                )}
                {renderMenuItem(
                    "/exam",
                    "/images/02.png",
                    "Thi thử",
                    "Thi thử theo cấu trúc đề",
                    true // Chỉ thành viên
                )}
                {renderMenuItem(
                    "/history",
                    "/images/03.png",
                    "Lịch sử",
                    "Xem lại lịch sử các bài thi",
                    true // Chỉ thành viên
                )}
                {renderMenuItem(
                    "/leaderboard",
                    "/images/04.png",
                    "Xếp hạng",
                    "Xem bảng xếp hạng",
                    true // Chỉ thành viên
                )}
                {renderMenuItem(
                    "/author",
                    "/images/05.png",
                    "Tác giả",
                    "Thông tin tác giả",
                    false // Cho phép khách truy cập
                )}
            </div>
            <footer className="home-footer">
                <p>Agriquiz © 2024 by Phan Tiến</p>
            </footer>
        </div>
    );
}

export default Home;