import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../common/Header'; // Thêm Header
import '../../styles/admin-login.css'; // Sử dụng lại style login

function AdminLogin() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleAdminLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${apiUrl}/admin-login.php`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                setMessage('Đăng nhập thành công');

                // Lưu token và vai trò vào localStorage
                localStorage.setItem('authToken', data.authToken);
                localStorage.setItem('role', 'admin');

                // Chuyển hướng về Admin Dashboard
                navigate('/admin');
            } else {
                setMessage(data.message || 'Tên đăng nhập hoặc mật khẩu không đúng');
            }
        } catch (error) {
            setMessage('Lỗi kết nối với server');
        }
    };

    return (
        <div className="login-page">
            <Header /> {/* Thêm Header */}
            
            <div className="login-container">
                <img src="/images/admin-logo.png" alt="Admin Logo" className="admin-logo" />
                <h2 className="login-title">Admin Portal</h2>
                <p className="login-description">Đăng nhập để truy cập trang quản trị</p>
                <form className="login-form" onSubmit={handleAdminLogin}>
                    <input
                        type="text"
                        placeholder="Tên đăng nhập"
                        className="input-field"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Mật khẩu"
                        className="input-field"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit" className="submit-button">Đăng Nhập</button>
                    {message && <p className="message">{message}</p>}
                </form>
            </div>
            
        
        </div>
    );
}

export default AdminLogin;
