import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Thêm Link để chuyển trang
import Header from '../common/Header';
import Footer from '../common/Footer';
import '../../styles/manage-questions.css';

function ManageQuestions() {
    const [questions, setQuestions] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [showImportModal, setShowImportModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [importFile, setImportFile] = useState(null);
    const [message, setMessage] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState({
        code: '',
        question: '',
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        correct_answer: '',
        explanation: '',
        shuffle_answers: false,
        category_id: '',
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [questionsPerPage] = useState(8);

    useEffect(() => {
        fetchQuestions();
        fetchCategories();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await fetch(`${apiUrl}/questions.php`);
            const data = await response.json();
            if (data.status === 'success') {
                setQuestions(data.questions);
            } else {
                setMessage('Không thể tải câu hỏi');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch(`${apiUrl}/categories.php`);
            const data = await response.json();
            if (data.status === 'success') {
                setCategories(data.categories);
            } else {
                setMessage('Không thể tải danh mục');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const handleImportQuestions = async (e) => {
        e.preventDefault();

        if (!importFile || !selectedCategoryId) {
            setMessage('Vui lòng chọn file Excel và danh mục.');
            return;
        }

        const formData = new FormData();
        formData.append('file', importFile);
        formData.append('category_id', selectedCategoryId);

        try {
            const response = await fetch(`${apiUrl}/questions.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.status === 'success') {
                fetchQuestions();
                setShowImportModal(false);
                setMessage(`Import thành công: ${data.importedCount}/${data.totalCount} câu hỏi.`);
            } else {
                setMessage(data.message || 'Lỗi khi import câu hỏi.');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const handleEditQuestion = (question) => {
        setSelectedQuestion(question);
        setShowEditModal(true);
    };

    const handleUpdateQuestion = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiUrl}/questions.php`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(selectedQuestion),
            });

            const data = await response.json();

            if (data.status === 'success') {
                fetchQuestions();
                setShowEditModal(false);
                setMessage('Cập nhật câu hỏi thành công.');
            } else {
                setMessage(data.message || 'Lỗi khi cập nhật câu hỏi.');
            }
        } catch (error) {
            setMessage('Lỗi kết nối server');
        }
    };

    const filteredQuestions = questions.filter((question) =>
        question.question.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastQuestion = currentPage * questionsPerPage;
    const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    const currentQuestions = filteredQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    const renderPagination = () => {
        const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);
        const maxPagesToShow = 10;
        const pages = [];

        if (totalPages <= maxPagesToShow) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            let startPage = Math.max(1, currentPage - 2);
            let endPage = Math.min(totalPages, currentPage + 2);

            if (startPage > 1) {
                pages.push(
                    <button key={1} onClick={() => setCurrentPage(1)} className={currentPage === 1 ? 'active' : ''}>
                        1
                    </button>
                );
                if (startPage > 2) pages.push(<span key="start-ellipsis">...</span>);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <button
                        key={i}
                        onClick={() => setCurrentPage(i)}
                        className={currentPage === i ? 'active' : ''}
                    >
                        {i}
                    </button>
                );
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) pages.push(<span key="end-ellipsis">...</span>);
                pages.push(
                    <button
                        key={totalPages}
                        onClick={() => setCurrentPage(totalPages)}
                        className={currentPage === totalPages ? 'active' : ''}
                    >
                        {totalPages}
                    </button>
                );
            }
        }

        return pages;
    };

    return (
        <div className="manage-questions-page">
            <Header />
            <main className="dashboard-container">
                <h2 className="dashboard-title">Quản lý câu hỏi</h2>
                <Link to="/admin" className="back-to-dashboard">← Trở về Dashboard</Link>
               <div className="action-bar">
    <input
        type="text"
        placeholder="Tìm kiếm câu hỏi..."
        className="search-input"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
    />
    <button className="btn-import" onClick={() => setShowImportModal(true)}>Import</button>
    <a href="http://localhost:8081/quiz-app/backend/api/questions.php?export=excel" className="btn-export" target="_blank" rel="noopener noreferrer">Xuất Excel</a>
</div>

                {message && <p className="message">{message}</p>}

                <table className="questions-table">
                    <thead>
                        <tr>
                            <th>Mã</th>
                            <th>Câu hỏi</th>
                            <th>Đáp án đúng</th>
                            <th>Chuyên mục</th>
                            <th>Chức năng</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentQuestions.map((question) => (
                            <tr key={question.id}>
                                <td>{question.code}</td>
                                <td>{question.question}</td>
                                <td>{question.correct_answer}</td>
                                <td>{question.category_name || 'Không có chuyên mục'}</td>
                                <td className="table-actions">
                                    <button className="btn-edit" onClick={() => handleEditQuestion(question)}>Sửa</button>
                                    <button className="btn-delete">Xóa</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="pagination">{renderPagination()}</div>
            </main>
            <Footer />
{showImportModal && (
    <div className="modal-overlay">
        <div className="modal">
            <h3>Import Câu Hỏi</h3>
            <form onSubmit={handleImportQuestions}>
                <select
                    value={selectedCategoryId || ''}
                    onChange={(e) => setSelectedCategoryId(e.target.value)}
                    className="select-category"
                    required
                >
                    <option value="">Chọn danh mục</option>
                    {categories.map((cat) => (
                        <option key={cat.id} value={cat.id}>
                            {cat.name}
                        </option>
                    ))}
                </select>
                <input
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={(e) => setImportFile(e.target.files[0])}
                    required
                />
                <div className="modal-actions">
                    <button type="submit" className="btn-primary">Import</button>
                    <button type="button" className="btn-secondary" onClick={() => setShowImportModal(false)}>
                        Hủy
                    </button>
                </div>
            </form>
        </div>
    </div>
)}

            {showEditModal && (
                <div className="modal-overlay">
                  <div className="modal">
    <h3>Sửa Câu Hỏi</h3>
    <form onSubmit={handleUpdateQuestion}>
    <input
        type="text"
        placeholder="Mã câu hỏi"
        className="input-field"
        value={selectedQuestion?.code || ''}
        onChange={(e) =>
            setSelectedQuestion({ ...selectedQuestion, code: e.target.value })
        }
        required
    />
    <textarea
        placeholder="Nội dung câu hỏi"
        className="textarea-field"
        value={selectedQuestion?.question || ''}
        onChange={(e) =>
            setSelectedQuestion({ ...selectedQuestion, question: e.target.value })
        }
        required
    ></textarea>

    <div className="answers-group">
        {['answer1', 'answer2', 'answer3', 'answer4'].map((key, index) => (
            <div className="answer-option" key={index}>
                <span>{String.fromCharCode(65 + index)}:</span>
                <input
                    type="text"
                    className="input-field"
                    value={selectedQuestion[key] || ''}
                    placeholder={`Đáp án ${index + 1}`}
                    onChange={(e) =>
                        setSelectedQuestion({ ...selectedQuestion, [key]: e.target.value })
                    }
                    required={index < 2} /* Bắt buộc cho A và B */
                />
            </div>
        ))}
    </div>

    <input
        type="text"
        placeholder="Đáp án đúng (A, B, C, D)"
        className="input-field"
        value={selectedQuestion?.correct_answer || ''}
        onChange={(e) =>
            setSelectedQuestion({ ...selectedQuestion, correct_answer: e.target.value })
        }
        required
    />
    
    <textarea
        placeholder="Giải thích (tuỳ chọn)"
        className="textarea-field"
        value={selectedQuestion?.explanation || ''}
        onChange={(e) =>
            setSelectedQuestion({ ...selectedQuestion, explanation: e.target.value })
        }
    ></textarea>

    <div className="shuffle-options">
        <label>
            <input
                type="checkbox"
                checked={selectedQuestion?.shuffle_answers || false}
                onChange={(e) =>
                    setSelectedQuestion({
                        ...selectedQuestion,
                        shuffle_answers: e.target.checked ? 1 : 0,
                    })
                }
            />
            Đảo đáp án
        </label>
    </div>

    <select
        value={selectedQuestion?.category_id || ''}
        onChange={(e) =>
            setSelectedQuestion({ ...selectedQuestion, category_id: e.target.value })
        }
        className="select-category"
        required
    >
        <option value="">Chọn danh mục</option>
        {categories.map((cat) => (
            <option key={cat.id} value={cat.id}>
                {cat.name}
            </option>
        ))}
    </select>

    <div className="modal-actions">
        <button type="submit" className="btn-primary">Lưu</button>
        <button type="button" className="btn-secondary" onClick={() => setShowEditModal(false)}>Hủy</button>
    </div>
</form>
</div>

                </div>
            )}
        </div>
    );
}

export default ManageQuestions;
